import * as React from "react"
import Layout from "../components/layout"
import Articles from "../components/articles"
import Helmet from 'react-helmet'

const BlogPage = () => {
  return (
  <>
<Helmet
    bodyAttributes={{
        class: 'blog-page whitemain'
    }}
/>
  <Layout pageTitle="Insights">

<Articles></Articles>
</Layout>
  </>
  )
}

export default BlogPage


export const Head = () => (
  <>
    <title>Blog Insights - Tobstar</title>
    <meta name="description" content="" />
  </>
)